var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-dialog',{attrs:{"max-width":"1200px","persistent":"","transition":"dialog-top-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.showform),callback:function ($$v) {_vm.showform=$$v},expression:"showform"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(" "+_vm._s(_vm.item.id > 0 ? "Well Formation " + _vm.item.formationname : "Add formation"))]),_c('v-spacer')],1),_c('v-card-text',[(_vm.item.id < 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.list.filter(function (elm) { return elm.active == 0; }),"label":"Formation","item-value":'id',"item-text":'formationname',"outlined":"","dense":"","chips":""},on:{"change":_vm.formation_change,"input":function($event){_vm.change++}},model:{value:(_vm.formation_id),callback:function ($$v) {_vm.formation_id=$$v},expression:"formation_id"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":'Depth From (' + _vm.depth_unit + ')',"readonly":!_vm.modify,"rules":[
                function (v) { return !v ||
                  v <= _vm.well.td ||
                  'Error ! : Max formation start depth : ' + _vm.well.td; } ],"hide-spin-buttons":"","clearable":""},on:{"input":function($event){_vm.change++},"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.depthfrom),callback:function ($$v) {_vm.$set(_vm.editedItem, "depthfrom", _vm._n($$v))},expression:"editedItem.depthfrom"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":'Depth To (' + _vm.depth_unit + ')',"disabled":""},model:{value:(_vm.editedItem.depthto),callback:function ($$v) {_vm.$set(_vm.editedItem, "depthto", _vm._n($$v))},expression:"editedItem.depthto"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"autocomplete":"off","type":"number","outlined":"","dense":"","label":'Depth (' + _vm.depth_unit + ')',"disabled":""},model:{value:(_vm.editedItem.depth),callback:function ($$v) {_vm.$set(_vm.editedItem, "depth", _vm._n($$v))},expression:"editedItem.depth"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":'TVD (' + _vm.depth_unit + ')',"readonly":!_vm.modify,"rules":[
                function (v) { return !v ||
                  v <= _vm.editedItem.depth ||
                  'Error ! : TVD Max : ' + _vm.editedItem.depth; } ],"hide-spin-buttons":"","clearable":""},on:{"input":function($event){_vm.change++},"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.tvd),callback:function ($$v) {_vm.$set(_vm.editedItem, "tvd", _vm._n($$v))},expression:"editedItem.tvd"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"autocomplete":"off","type":"number","outlined":"","dense":"","label":'Formation Pressure (' + _vm.pressure_unit + ')',"readonly":!_vm.modify,"clearable":"","disabled":_vm.editedItem.depth == 0 || _vm.editedItem.depth === null,"hide-spin-buttons":""},on:{"input":function($event){_vm.change++},"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.formationpr),callback:function ($$v) {_vm.$set(_vm.editedItem, "formationpr", _vm._n($$v))},expression:"editedItem.formationpr"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"autocomplete":"off","type":"number","outlined":"","dense":"","label":'Surface Pressure (' + _vm.pressure_unit + ')',"readonly":!_vm.modify,"clearable":"","disabled":_vm.editedItem.depth == 0 || _vm.editedItem.depth === null,"hide-spin-buttons":""},on:{"input":function($event){_vm.change++},"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.surfacepr),callback:function ($$v) {_vm.$set(_vm.editedItem, "surfacepr", _vm._n($$v))},expression:"editedItem.surfacepr"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"2"}},[_c('v-checkbox',{staticClass:"ma-2",attrs:{"label":"Potential to flow","readonly":!_vm.modify,"disabled":_vm.fluids_list.length == 0 ||
                _vm.editedItem.depth == 0 ||
                _vm.editedItem.depth === null},on:{"change":function($event){_vm.change++}},model:{value:(_vm.editedItem.pff),callback:function ($$v) {_vm.$set(_vm.editedItem, "pff", $$v)},expression:"editedItem.pff"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"10","md":"10"}},[_c('v-autocomplete',{attrs:{"items":_vm.fluids,"label":"Fluid Type","item-value":'id',"item-text":'label',"outlined":"","clearable":"","disabled":_vm.editedItem.depth == 0 || _vm.editedItem.depth === null,"dense":"","chips":"","multiple":""},on:{"input":function($event){_vm.change++}},model:{value:(_vm.fluids_list),callback:function ($$v) {_vm.fluids_list=$$v},expression:"fluids_list"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":_vm.change == 0},on:{"click":function($event){$event.stopPropagation();return _vm.save.apply(null, arguments)}}},[_vm._v(" Save ")]),_c('v-btn',{attrs:{"color":"blue darken-1"},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" Close ")])],1)],1)],1),_c('confirmdialog',{ref:"confirm"}),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }