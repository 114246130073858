<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showform"
      max-width="1200px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span>
            {{
              item.id > 0
                ? "Well Formation " + item.formationname
                : "Add formation"
            }}</span
          >
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row v-if="item.id < 0">
            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                :items="list.filter((elm) => elm.active == 0)"
                v-model="formation_id"
                label="Formation"
                :item-value="'id'"
                :item-text="'formationname'"
                outlined
                dense
                chips
                @change="formation_change"
                @input="change++"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                outlined
                dense
                v-model.number="editedItem.depthfrom"
                :label="'Depth From (' + depth_unit + ')'"
                :readonly="!modify"
                @input="change++"
                @focus="$event.target.select()"
                :rules="[
                  (v) =>
                    !v ||
                    v <= well.td ||
                    'Error ! : Max formation start depth : ' + well.td,
                ]"
                hide-spin-buttons
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                outlined
                dense
                v-model.number="editedItem.depthto"
                :label="'Depth To (' + depth_unit + ')'"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                autocomplete="off"
                type="number"
                outlined
                dense
                v-model.number="editedItem.depth"
                :label="'Depth (' + depth_unit + ')'"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                outlined
                dense
                v-model.number="editedItem.tvd"
                :label="'TVD (' + depth_unit + ')'"
                :readonly="!modify"
                @input="change++"
                @focus="$event.target.select()"
                :rules="[
                  (v) =>
                    !v ||
                    v <= editedItem.depth ||
                    'Error ! : TVD Max : ' + editedItem.depth,
                ]"
                hide-spin-buttons
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                autocomplete="off"
                type="number"
                outlined
                dense
                v-model.number="editedItem.formationpr"
                :label="'Formation Pressure (' + pressure_unit + ')'"
                :readonly="!modify"
                @input="change++"
                @focus="$event.target.select()"
                clearable
                :disabled="editedItem.depth == 0 || editedItem.depth === null"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                autocomplete="off"
                type="number"
                outlined
                dense
                v-model.number="editedItem.surfacepr"
                :label="'Surface Pressure (' + pressure_unit + ')'"
                :readonly="!modify"
                @input="change++"
                @focus="$event.target.select()"
                clearable
                :disabled="editedItem.depth == 0 || editedItem.depth === null"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1" md="2">
              <v-checkbox
                v-model="editedItem.pff"
                label="Potential to flow"
                :readonly="!modify"
                @change="change++"
                :disabled="
                  fluids_list.length == 0 ||
                  editedItem.depth == 0 ||
                  editedItem.depth === null
                "
                class="ma-2"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" sm="10" md="10">
              <v-autocomplete
                :items="fluids"
                v-model="fluids_list"
                label="Fluid Type"
                :item-value="'id'"
                :item-text="'label'"
                outlined
                clearable
                :disabled="editedItem.depth == 0 || editedItem.depth === null"
                dense
                chips
                @input="change++"
                multiple
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn
            color="blue darken-1"
            :disabled="change == 0"
            @click.stop="save"
          >
            Save
          </v-btn>
          <v-btn color="blue darken-1" @click.stop="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-form>
</template>
<script>
//import UPDATE_FORMATION from "../graphql/Well/UPDATE_FORMATION.gql";
import FORMATION_UPDATE from "../graphql/Well/FORMATION_UPDATE.gql";

export default {
  components: {
    // listitems: () => import("../components/ListItems.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    list: Array,
    showform: Boolean,
    modify: Boolean,
    item: Object,
    depth_unit: String,
    pressure_unit: String,
    fluids: Array,
    well: Object,
  },
  data() {
    return {
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      editedItem: {},
      change: 0,
      depth_min: 0,
      depth_max: 0,
      fluid: {},
      formation_id: null,
      actualfluid: {},
      newfluid: {},
      data_loading: false,

      fluids_list: [],
    };
  },
  watch: {},
  created() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);

        this.fluids_list = this.editedItem.fluids
          ? this.editedItem.fluids.map((elm) => elm.fluid_id)
          : [];
      }
    }
  },
  computed: {
    depthfrom_error() {
      let i = this.list.findIndex(
        (elm) =>
          (elm.formation_order > this.editedItem.formation_order &&
            elm.depthfrom <= this.editedItem.depthfrom &&
            elm.depthfrom > 0 &&
            elm.depthfrom != null) ||
          (elm.formation_order < this.editedItem.formation_order &&
            elm.depthfrom >= this.editedItem.depthfrom &&
            elm.depthfrom > 0 &&
            elm.depthfrom != null)
      );

      return i >= 0;
    },
  },
  methods: {
    formation_change() {
      let i = this.list.findIndex((elm) => elm.id == this.formation_id);
      if (i >= 0) {
        this.editedItem = this.list[i];
      }
    },
    close() {
      this.$emit("close");
    },

    async save() {
      if (this.$refs.form.validate()) {
        if (this.editedItem.id > -1) {
          let v = {
            list: [
              {
                id: this.editedItem.id,
                depthfrom: this.editedItem.depthfrom,
                tvd: this.editedItem.tvd,
                pff: this.editedItem.pff ? 1 : 0,
                formationpr: this.editedItem.formationpr,
                surfacepr: this.editedItem.surfacepr,
                fluids: this.fluids_list.length > 0 ? this.fluids_list : null,
              },
            ],
          };
          let r = await this.$maj(FORMATION_UPDATE, v);
          if (r.ok) {
            this.$emit("change", this.editedItem);
            this.list.splice(this.editedItem.index, 1, this.editedItem);
          } else {
            this.snackbar_text = "Saving Error";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        }
        this.change = 0;
        this.$emit("close");
      }
    },
    numberWithSpace: function (x) {
      return x
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "0";
    },
  },
};
</script>
